<template>
  <b-button
    v-if="$can(action,resourceName)"
    :to="{ name: addRouteName }"
    variant="primary"
  >
    <span class="text-nowrap">{{ text }}</span>
  </b-button>
</template>
<script>
export default {
  name: 'AddResource',
  props: {
    addRouteName: { type: String, default: '' },
    resourceName: { type: String, default: '' },
    action: { type: String, default: 'store' },
    text: { type: String, default: 'Add' },
  },
}
</script>
<style lang="scss">

</style>
