<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        v-if="$can('sendToListOfUsers','NotificationTokenController')"
        resource-name="NotificationTokenController"
        add-route-name="organizations-notifications-add"
        action="sendToListOfUsers"
      />
    </div>

    <b-row>
      <b-col>
        <b-card header="Search">
          <b-form-row>
            <b-col
              md="6"
              class="mb-1 mb-md-0"
            >
              <search
                placeholder="Title"
                :search-query.sync="searchQuery"
                @refresh="refreshTable"
              />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>
    <b-row align-h="center">
      <b-col>
        <b-card>
          <b-table
            ref="table"
            :items="getNotifications"
            :fields="tableColumns"
            show-empty
            responsive
            empty-text="No notifications found"
          >
            <template #cell(is_sent)="{item}">
              {{ item.is_sent ? 'Yes' : 'No' }}
            </template>
            <template
              #cell(actions)="{item}"
            >
              <b-dropdown
                v-if="!item.is_sent"
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  v-if="$can('deleteNotification','NotificationController')"
                  @click="deleteRecord(deleteEndPoint,item.chunk)"
                >
                  <feather-icon
                    class="text-danger"
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50 text-danger"
                  >
                    Delete
                  </span>
                </b-dropdown-item>

              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'NotificationsList',
  components: {
    AddResource,
    Search,
    SearchButton,
  },
  data() {
    return {
      searchQuery: '',
      tableColumns: [
        { key: 'title' },
        { key: 'body' },
        { key: 'sends_at', label: 'Sent At' },
        { key: 'is_sent', label: 'Sent' },
        { key: 'actions' },
      ],
      loading: true,
      deleteEndPoint: '/internalops/admin-notification-delete',
    }
  },
  setup() {
    const { successfulOperationAlert, confirmOperation } = handleAlerts()
    return { successfulOperationAlert, confirmOperation }
  },
  methods: {
    getNotifications() {
      const promise = this.$portalUsers.post(`/internalops/notifications/organization/${this.$store.getters['mainEntity/getEntityId']}`, {
        params: {
          // target: '',
          // title: this.searchQuery,
        },
      })

      return promise.then(res => {
        const notifications = res.data.data

        return notifications || []
      }).finally(() => {
        this.loading = false
      })
    },
    deleteRecord(deleteEndPoint, chunk) {
      this.confirmOperation().then(() => {
        this.$portalUsers.post(deleteEndPoint, { chunk },
          {
            headers: {
              entityId: this.$store.getters['mainEntity/getEntityId'],
            },
          }).then(() => {
          this.successfulOperationAlert('Notification is deleted successully')
          this.$refs.table.refresh()
        })
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">

</style>
